import Vue from 'vue'

Vue.filter('capitalize', (val) => val.toUpperCase())

Vue.filter('formatDate', (value) => {
    const date = new Date(value)
    return date.toLocaleString(['en-US'], {
      month: 'short',
      day: '2-digit',
      year: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
    })
  })

Vue.filter('truncate', (value, limit) => {
    if (value.length > limit) {
        value = value.substring(0, (limit - 3)) + '...';
    }
    return value
})