import Vuex from 'vuex';

const createStore = () => {
	return new Vuex.Store({
		state: () => ({
			popactive: false,
			cmsdata: {},
			pages: {},
			posts: {},
			projects: {},
			categories: {},
			data: {}
		}),
		getters: {
			cmsdata(state) {
				// console.log('getter - cmsdata ', state.cmsdata)
				return state.cmsdata
			},
			pages(state) {
				// console.log('getter - posts ', state.posts)
				return state.pages
			},
			posts(state) {
				// console.log('getter - posts ', state.posts)
				return state.posts
			},
			projects(state) {
				// console.log('getter - projects ', state.projects)
				return state.projects
			},
			categories(state) {
				// console.log('getter - categories ', state.categories)
				return state.categories
			}
		},
		mutations: {
			setData(state, obj) {
				// console.log('VUEX STORE - setData: ', obj.slug, obj.data)
                if (obj.data !== undefined) {
					state.cmsdata[obj.slug] = obj.data;
				}
			},
			setPages(state, obj) {
				// console.log('VUEX STORE - setData: ', obj.slug, obj.data)
                if (obj.data !== undefined) {
					state.pages[obj.slug] = obj.data;
				}
			},
			setPosts(state, obj) {
				// console.log('VUEX STORE - setPosts: ', obj.slug, obj.data)
                if (obj.data !== undefined) {
					state.posts[obj.slug] = obj.data;
				}
			},
			setProjects(state, obj) {
				// console.log('VUEX STORE - setProjects: ', obj.slug, obj.data)
                if (obj.data !== undefined) {
					state.projects[obj.slug] = obj.data;
				}
			},
			setCategories(state, obj) {
				// console.log('VUEX STORE - setProjects: ', obj.slug, obj.data)
                if (obj.data !== undefined) {
					state.categories[obj.slug] = obj.data;
				}
			},
			setPopup(state, data) {
				// console.log('VUEX STORE - POPUP DATA')
				state.popactive = JSON.parse(data);
			}
		},
		actions: {
			async getData({
				commit
			},  url) {
				if(url) {
					// console.log("getData url: ", url)
					if (url.replace(/\/\s*$/,'').split('/').length > 1){
						const parts = url.replace(/\/\s*$/,'').split('/')
						url = parts[parts.length - 1]
					}
					if(url === '/') {url='home'}
				}
				try {
					await this.$strapi.find('pages', {slug: url}).then(function (response) {
						// console.log("getData: ", url, response);
						if(response !== undefined && response.length > 0){
							commit('setData', {
								'slug': url,
								data: response
							})
						}else{
							Error('error');
						}
					});
				} catch (error) {
					Error('error');
				}
			},
			async getPagesAll({
				commit
			},  url) {
				try {
					await this.$strapi.find('pages').then(function (response) {
						// console.log("getData: ", url, response);
						if(response !== undefined && response.length > 0){
							response.forEach((post, index) => { 
								commit('setPages', {
									'slug': post.slug,
									data: post
								})
							 } )
						}else{
							Error('error');
						}
					});
				} catch (error) {
					Error('error');
				}
			},
			async getPosts({
				commit
			},  url) {
				if(url) {
					// console.log("getData url: ", url)
					if (url.replace(/\/\s*$/,'').split('/').length > 1){
						const parts = url.replace(/\/\s*$/,'').split('/')
						url = parts[parts.length - 1]
					}
					if(url === '/') {url='news'}
				}
				try {
					await this.$strapi.find('posts', {slug: url}).then(function (response) {
						// console.log("getData: ", url, response);
						if(response !== undefined && response.length > 0){
							commit('setPosts', {
								'slug': url,
								data: response[0]
							})
						}else{
							Error('error');
						}
					});
				} catch (error) {
					Error('error');
				}
			},
			async getPostsAll({
				commit
			},  url) {
				try {
					await this.$strapi.find('posts').then(function (response) {
						// console.log("getData: ", url, response);
						if(response !== undefined && response.length > 0){
							response.forEach((post, index) => { 
								commit('setPosts', {
									'slug': post.slug,
									data: post
								})
							 } )
						}else{
							Error('error');
						}
					});
				} catch (error) {
					Error('error');
				}
			},
			async getProjects({
				commit
			},  url) {
				if(url) {
					// console.log("getData url: ", url)
					if (url.replace(/\/\s*$/,'').split('/').length > 1){
						const parts = url.replace(/\/\s*$/,'').split('/')
						url = parts[parts.length - 1]
					}
					if(url === '/') {url='projects'}
				}
				try {
					await this.$strapi.find('projects', {slug: url}).then(function (response) {
						// console.log("getData: ", url, response);
						if(response !== undefined && response.length > 0){
							commit('setProjects', {
								'slug': url,
								data: response[0]
							})
						}else{
							Error('error');
						}
					});
				} catch (error) {
					Error('error');
				}
			},
			async getProjectsAll({
				commit
			},  url) {
				try {
					await this.$strapi.find('projects').then(function (response) {
						// console.log("getData: ", url, response);
						if(response !== undefined && response.length > 0){
							response.forEach((post, index) => { 
								commit('setProjects', {
									'slug': post.slug,
									data: post
								})
							 } )
						}else{
							Error('error');
						}
					});
				} catch (error) {
					Error('error');
				}
			},
			async nuxtServerInit({
				commit
			}, {
				req
			}) {
                // console.log("nuxt init: ", process.env.strapiUrl, req.url)
				let url = req.url;
				if(url) {
					if (url.replace(/\/\s*$/,'').split('/').length > 1){
						const parts = url.replace(/\/\s*$/,'').split('/')
						url = parts[parts.length - 1]
					}
					if(url === '/' || url === '/index.html.var' || url === 'index.html.var') {url='home'}
				}
				const data = this.$strapi.find('pages')
                    .then(response => {
						response.forEach((post, index) => { 
							commit('setPages', {
								'slug': post.slug,
								data: post
							})
						 } )
                    })
                    .catch(error => {
                        console.error(error);
                        Error(error);
                    });
                const data1 = this.$strapi.find('pages', {slug: url})
                    .then(response => {
                        commit('setData', {
                            'slug': url,
                            data: response
                        })
                    })
                    .catch(error => {
                        console.error(error);
                        Error(error);
                    });

                const data2 = this.$strapi.find('menu')
                    .then(response => {
                        commit('setData', {
                            'slug': 'navigation',
                            data: response
                        })
                    })
                    .catch(error => {
                        console.error(error);
                        Error(error);
                    });
				const data3 = this.$strapi.find('mobile-menu')
                    .then(response => {
                        commit('setData', {
                            'slug': 'mobilenavigation',
                            data: response
                        })
                    })
                    .catch(error => {
                        console.error(error);
                        Error(error);
                    });	
				const data4 = this.$strapi.find('posts')
                    .then(response => {
						response.forEach((post, index) => { 
							commit('setPosts', {
								'slug': post.slug,
								data: post
							})
						 } )
                    })
                    .catch(error => {
                        console.error(error);
                        Error(error);
                    });
				const data5 = this.$strapi.find('projects')
                    .then(response => {
						response.forEach((post, index) => { 
							commit('setProjects', {
								'slug': post.slug,
								data: post
							})
						 } )
                    })
                    .catch(error => {
                        console.error(error);
                        Error(error);
                    });

				const data6 = this.$strapi.find('categories')
                    .then(response => {
						response.forEach((post, index) => { 
							commit('setCategories', {
								'slug': post.Slug,
								data: post
							})
						 } )
                    })
                    .catch(error => {
                        console.error(error);
                        Error(error);
                    });	

            await Promise.all([data, data1, data2, data3, data4, data5, data6]);


			}
		}
	})
}

export default createStore