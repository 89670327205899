//
//
//
//
//
//
//
//

import InlineIcon from '~/assets/northbank_logo_vertical.svg?inline'

export default {
	components: {
		InlineIcon,
	},
	data: () => ({
		loading: false,
	}),
	transition: {
		name: 'fade',
		mode: 'in-out',
		css: true,
		duration: {
			enter: 500,
			leave: 500,
		},
		type: 'animation',
	},
	methods: {
		start() {
			// console.log('LOADING')
			this.loading = true
		},
		finish() {
			// console.log('LOADING END')
			// setTimeout(() => (this.loading = false), 5000)
			this.loading = false
		},
	},
}
