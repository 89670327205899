export const CookieNotice = () => import('../../components/modules/CookieNotice.vue' /* webpackChunkName: "components/cookie-notice" */).then(c => wrapFunctional(c.default || c))
export const FormContactUs = () => import('../../components/modules/FormContactUs.vue' /* webpackChunkName: "components/form-contact-us" */).then(c => wrapFunctional(c.default || c))
export const GoogleMap = () => import('../../components/modules/GoogleMap.vue' /* webpackChunkName: "components/google-map" */).then(c => wrapFunctional(c.default || c))
export const Loading = () => import('../../components/modules/Loading.vue' /* webpackChunkName: "components/loading" */).then(c => wrapFunctional(c.default || c))
export const ResponsiveImage = () => import('../../components/modules/ResponsiveImage.vue' /* webpackChunkName: "components/responsive-image" */).then(c => wrapFunctional(c.default || c))
export const ResponsiveImageStrapi = () => import('../../components/modules/ResponsiveImageStrapi.vue' /* webpackChunkName: "components/responsive-image-strapi" */).then(c => wrapFunctional(c.default || c))
export const SchemaFooter = () => import('../../components/modules/SchemaFooter.vue' /* webpackChunkName: "components/schema-footer" */).then(c => wrapFunctional(c.default || c))
export const Sitemap = () => import('../../components/modules/Sitemap.vue' /* webpackChunkName: "components/sitemap" */).then(c => wrapFunctional(c.default || c))
export const Social = () => import('../../components/modules/Social.vue' /* webpackChunkName: "components/social" */).then(c => wrapFunctional(c.default || c))
export const SocialShare = () => import('../../components/modules/SocialShare.vue' /* webpackChunkName: "components/social-share" */).then(c => wrapFunctional(c.default || c))
export const Video = () => import('../../components/modules/Video.vue' /* webpackChunkName: "components/video" */).then(c => wrapFunctional(c.default || c))
export const VideoStrapi = () => import('../../components/modules/VideoStrapi.vue' /* webpackChunkName: "components/video-strapi" */).then(c => wrapFunctional(c.default || c))
export const PageHome = () => import('../../components/pages/PageHome.vue' /* webpackChunkName: "components/page-home" */).then(c => wrapFunctional(c.default || c))
export const PageHomeSlider = () => import('../../components/pages/PageHomeSlider.vue' /* webpackChunkName: "components/page-home-slider" */).then(c => wrapFunctional(c.default || c))
export const AppFooter = () => import('../../components/AppFooter.vue' /* webpackChunkName: "components/app-footer" */).then(c => wrapFunctional(c.default || c))
export const AppHeader = () => import('../../components/AppHeader.vue' /* webpackChunkName: "components/app-header" */).then(c => wrapFunctional(c.default || c))
export const SubHeader = () => import('../../components/SubHeader.vue' /* webpackChunkName: "components/sub-header" */).then(c => wrapFunctional(c.default || c))
export const DynamicComponent = () => import('../../components/dynamicComponent.vue' /* webpackChunkName: "components/dynamic-component" */).then(c => wrapFunctional(c.default || c))
export const HeroesHeroHomeSlider = () => import('../../components/heroes/HeroHomeSlider.vue' /* webpackChunkName: "components/heroes-hero-home-slider" */).then(c => wrapFunctional(c.default || c))
export const HeroesHeroImage = () => import('../../components/heroes/HeroImage.vue' /* webpackChunkName: "components/heroes-hero-image" */).then(c => wrapFunctional(c.default || c))
export const ProjectsProjectGalleryGrid = () => import('../../components/projects/ProjectGalleryGrid.vue' /* webpackChunkName: "components/projects-project-gallery-grid" */).then(c => wrapFunctional(c.default || c))
export const ProjectsProjectHero = () => import('../../components/projects/ProjectHero.vue' /* webpackChunkName: "components/projects-project-hero" */).then(c => wrapFunctional(c.default || c))
export const ProjectsRelatedProjectSlider = () => import('../../components/projects/RelatedProjectSlider.vue' /* webpackChunkName: "components/projects-related-project-slider" */).then(c => wrapFunctional(c.default || c))
export const PostsPostContent = () => import('../../components/posts/PostContent.vue' /* webpackChunkName: "components/posts-post-content" */).then(c => wrapFunctional(c.default || c))
export const PostsPostHero = () => import('../../components/posts/PostHero.vue' /* webpackChunkName: "components/posts-post-hero" */).then(c => wrapFunctional(c.default || c))
export const SectionsSectionAwards = () => import('../../components/sections/SectionAwards.vue' /* webpackChunkName: "components/sections-section-awards" */).then(c => wrapFunctional(c.default || c))
export const SectionsSectionContactForm = () => import('../../components/sections/SectionContactForm.vue' /* webpackChunkName: "components/sections-section-contact-form" */).then(c => wrapFunctional(c.default || c))
export const SectionsSectionContent = () => import('../../components/sections/SectionContent.vue' /* webpackChunkName: "components/sections-section-content" */).then(c => wrapFunctional(c.default || c))
export const SectionsSectionContentImage = () => import('../../components/sections/SectionContentImage.vue' /* webpackChunkName: "components/sections-section-content-image" */).then(c => wrapFunctional(c.default || c))
export const SectionsSectionContentQuote = () => import('../../components/sections/SectionContentQuote.vue' /* webpackChunkName: "components/sections-section-content-quote" */).then(c => wrapFunctional(c.default || c))
export const SectionsSectionCta = () => import('../../components/sections/SectionCta.vue' /* webpackChunkName: "components/sections-section-cta" */).then(c => wrapFunctional(c.default || c))
export const SectionsSectionGallery = () => import('../../components/sections/SectionGallery.vue' /* webpackChunkName: "components/sections-section-gallery" */).then(c => wrapFunctional(c.default || c))
export const SectionsSectionGalleryGrid = () => import('../../components/sections/SectionGalleryGrid.vue' /* webpackChunkName: "components/sections-section-gallery-grid" */).then(c => wrapFunctional(c.default || c))
export const SectionsSectionHomeTiles = () => import('../../components/sections/SectionHomeTiles.vue' /* webpackChunkName: "components/sections-section-home-tiles" */).then(c => wrapFunctional(c.default || c))
export const SectionsSectionIconSlider = () => import('../../components/sections/SectionIconSlider.vue' /* webpackChunkName: "components/sections-section-icon-slider" */).then(c => wrapFunctional(c.default || c))
export const SectionsSectionImage = () => import('../../components/sections/SectionImage.vue' /* webpackChunkName: "components/sections-section-image" */).then(c => wrapFunctional(c.default || c))
export const SectionsSectionMap = () => import('../../components/sections/SectionMap.vue' /* webpackChunkName: "components/sections-section-map" */).then(c => wrapFunctional(c.default || c))
export const SectionsSectionNews = () => import('../../components/sections/SectionNews.vue' /* webpackChunkName: "components/sections-section-news" */).then(c => wrapFunctional(c.default || c))
export const SectionsSectionNewsRecent = () => import('../../components/sections/SectionNewsRecent.vue' /* webpackChunkName: "components/sections-section-news-recent" */).then(c => wrapFunctional(c.default || c))
export const SectionsSectionPostsSlider = () => import('../../components/sections/SectionPostsSlider.vue' /* webpackChunkName: "components/sections-section-posts-slider" */).then(c => wrapFunctional(c.default || c))
export const SectionsSectionProjectsGrid = () => import('../../components/sections/SectionProjectsGrid.vue' /* webpackChunkName: "components/sections-section-projects-grid" */).then(c => wrapFunctional(c.default || c))
export const SectionsSectionProjectsMap = () => import('../../components/sections/SectionProjectsMap.vue' /* webpackChunkName: "components/sections-section-projects-map" */).then(c => wrapFunctional(c.default || c))
export const SectionsSectionProjectsSlider = () => import('../../components/sections/SectionProjectsSlider.vue' /* webpackChunkName: "components/sections-section-projects-slider" */).then(c => wrapFunctional(c.default || c))
export const SectionsSectionTiles = () => import('../../components/sections/SectionTiles.vue' /* webpackChunkName: "components/sections-section-tiles" */).then(c => wrapFunctional(c.default || c))

// nuxt/nuxt.js#8607
function wrapFunctional(options) {
  if (!options || !options.functional) {
    return options
  }

  const propKeys = Array.isArray(options.props) ? options.props : Object.keys(options.props || {})

  return {
    render(h) {
      const attrs = {}
      const props = {}

      for (const key in this.$attrs) {
        if (propKeys.includes(key)) {
          props[key] = this.$attrs[key]
        } else {
          attrs[key] = this.$attrs[key]
        }
      }

      return h(options, {
        on: this.$listeners,
        attrs,
        props,
        scopedSlots: this.$scopedSlots,
      }, this.$slots.default)
    }
  }
}
