import Vue from "vue";
import VueGtag from "vue-gtag";

Vue.use(VueGtag, {
  config: { 
    id: process.env.googleanalyticsid,
    params: {
      // send_page_view: false
    }
  }
})
