//
//
//
//
//
//
//
//
//
//
//
//
//
//



export default {
  props: ['error'],
  // layout: 'error' // you can set a custom layout for the error page
}
